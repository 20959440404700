import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import BasePage from '../components/Layout/BasePage/BasePage';

import { AppRouteType } from '../models';
import { Spinner } from '../components/Common';
import useAuthContext from '../store/auth-context';
import { hasPermission } from '../utils/permission';

const Home = lazy(() => import('../pages/Home'));
const Login = lazy(() => import('../pages/Login'));
const NotFound = lazy(() => import('../pages/404'));
const AgencyDetails = lazy(() => import('../pages/Agency'));

const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={<Spinner />}>
    <Tag />
  </Suspense>
);

export const routePage = {
  BASE: 0,
  GROUPE: 1,
  POINTS_DE_COLLECTS: 2,
};

export const routes: AppRouteType[] = [
  {
    name: 'Login',
    auth: false,
    path: 'login',
    component: Login,
  },
  {
    name: '404',
    path: '404',
    component: NotFound,
    isPublic: true,
  },
  {
    name: 'List of agencies',
    auth: true,
    path: '/',
    component: Home,
  },
  {
    name: 'Agency',
    auth: true,
    path: 'agency/:agencyId',
    component: AgencyDetails,
    roles: routePage.GROUPE,
  },
];

export default function RoutesAppRoutes() {
  const { accessToken, profile } = useAuthContext();

  const publicRoutes = routes
    .filter((route) => !route.auth || route.isPublic)
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  // public routes
  if (!accessToken) return <Routes>{publicRoutes}</Routes>;

  // authenticated routes
  const authenticatedRoutes = routes
    .filter(
      (route) =>
        (route.auth && hasPermission(route.roles, profile)) || route.isPublic
    )
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  return (
    <BasePage>
      <Routes>{authenticatedRoutes}</Routes>
    </BasePage>
  );
}
